<template>
  <!-- <el-footer> -->
  <p class="d-flex ai-center jc-center footer">
    <!-- © Sky Mavis  -->
    <a :href="sets.website_url" target="_blank">
      <img src="../assets/menu/website.png" alt="twitter" />
    </a>
    <a :href="sets.twitter_url" target="_blank">
      <img src="../assets/menu/twitter.png" alt="twitter" />
    </a>
    <a :href="sets.telegram_url" target="_blank">
      <img data-v-7e06a71a src="../assets/menu/dianbao_zh.png" alt="telegram" />
    </a>
    <!-- <a href="https://www.instagram.com/metadragondao" target="_blank" style="color: #ff6700">
      <img data-v-7e06a71a src="../assets/menu/Instagram.png" alt="Instagram" />
    </a>
    <a href="https://discord.gg/E9wsENrGMk" target="_blank">
      <img data-v-7e06a71a src="../assets/menu/discord.png" alt="discord" />
    </a>
    <a :href="whitepaperUrl" target="_blank">
      <img data-v-7e06a71a src="../assets/menu/whitepaper.png" alt="whitepaper" />
    </a> -->
    <span
      class="lang"
      :class="['hover-c-m', { 'c-m': item.locale == locale }]"
      @click="localeFun(item)"
      v-for="(item, i) in locales"
      :key="i"
    >{{ item.name }}</span>
  </p>
  <!-- </el-footer> -->
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "Bot",
  inject: ["reload"],
  data() {
    return {
      locale: "",
      locales: [
        { locale: "tw", name: "繁體中文" },
        { locale: "en", name: "English" },
        // { locale: "in", name: "Indonesia" }
      ]
    };
  },
  computed: {
    ...mapState(['sets']),
    whitepaperUrl() {
      let lang;
      if (this._i18n.locale == "tw") {
        lang = "zh/";
      } else if (this._i18n.locale == "en") {
        lang = "en/";
      } else {
        lang = "id/";
      }
      return "https://metadragon.gitbook.io/" + lang;
    }
  },
  mounted() {
    console.log(this.$storage.get("locale"));
    this._i18n.locale = this.$storage.get("locale")
      ? this.$storage.get("locale")
      : "en";
    this.locale = this.$storage.get("locale")
      ? this.$storage.get("locale")
      : "en";
  },
  methods: {
    localeFun(item) {
      if (item.locale == this.locale) return false;
      if (this.locales.some(o => o.locale == item.locale)) {
        this.locale = item.locale;
        this._i18n.locale = item.locale;
        this.$storage.set("locale", item.locale);
      }
      this.reload();
    }
  }
};
</script>

<style lang="scss" scope>
.footer {
  font-size: 14px;
  color: #fff;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  line-height: 60px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  img {
    height: 25px;
    vertical-align: middle;
  }
  a {
    margin-right: 10px;
  }
  span + span {
    margin-left: 5px;
  }
}
.lang {
  white-space: nowrap;
}
.c-m {
  color: #37b497;
}
.hover-c-m:hover {
  color: #37b497;
  cursor: pointer;
}
</style>
