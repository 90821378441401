import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routerList = [];

function importAll(r){
  r.keys().forEach(
    (key) => {
      if(!!r(key).default){
        routerList.push(...r(key).default)
      }
    }
  )
}

importAll(require.context('./modules',true,/\.router\.js$/));

const routers = [
  ...routerList
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}

export default routers
