//过滤器 {{num|notation}}


//展示余额，如果余额为空或者未0，则显示0.00
export function notation(num) {
    const str = (num * 1).toFixed(6);
    const length = str.length;
    for (let i = length; i >= 0; i--) {
        if (str[i - 1] != "0") {
            return str.substring(0, str[i - 1] != "." ? i : i + 2);
        }
    }
    return num * 1;
}

//百分比显示，保留两位小数
export function rising(price) {
    if (price * 1 > 0) return `+${(price * 1).toFixed(2)}%`;
    return `${(price * 1).toFixed(2)}%`;
}

//保留小数点任意数字。默认保留整数
export function Fixed(price, num) {
    if (price == 0) return (0);
    if (!price) return 0;
    return (price * 1).toFixed(num);
}

//日期选择，0显示月日 1显示时分 不填写显示完整
export function times(times, num) {
    if (!times || times.indexOf("-") == -1) return times;
    if (num == 0) return times.split(" ")[0];
    if (num == 1) return times.split(" ")[1];
    return times.split("-")[1] + "-" + times.split("-")[2];
}

//去掉小数末尾多余的零
export function cutZero(value) {
    return value ? parseFloat(value) : 0
}

//中间用...代替
export function plusXing(str, frontLen, endLen) {
    var len = str.length - frontLen - endLen;
    var xing = '.....';
    // for (var i = 0; i < len; i++) {
    //   xing += '*';
    // }
    if (str.length <= frontLen + endLen) {
        return str
    } else {
        return str.substr(0, frontLen) + xing + str.substr(str.length - endLen);
    }
}

// 字符超出指定个数显示省略号
export function omit(str) {
    if (str.length > 10) {      //如果字符长度超过10，后面的字符就变成...可自行调整长度和代替字符
        str = str.substr(0, 10) + "..."   //截取从第一个字符开始，往后取10个字符，剩余的用...代替
        return str
    } else {
        return str
    }
}

//千分位显示
export function format(num) {
    var reg = /\d{1,3}(?=(\d{3})+$)/g;
    return (num + '').replace(reg, '$&,');
}

//转意符换成普通字符
function convertIdeogramToNormalCharacter(val) {
    const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
    return val.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) { return arrEntities[t]; });
}

// 获取富文本的纯文字内容
export function getPlainText(richCont) {
    let value = richCont;
    if (richCont) {
        // 方法一： 
        value = value.replace(/\s*/g, "");  //去掉空格
        value = value.replace(/<[^>]+>/g, ""); //去掉所有的html标记
        value = value.replace(/↵/g, "");     //去掉所有的↵符号
        value = value.replace(/[\r\n]/g, "") //去掉回车换行
        value = value.replace(/&nbsp;/g, "") //去掉空格
        value = convertIdeogramToNormalCharacter(value);
        value.length > 10 ? value = value.substr(0, 10) + "..." : value
        return value;
    } else {
        return null;
    }
}

export function formatAddress(str) {
    if (!str) return "--";
    const str1 = str.substring(0, 5);
    const str2 = str.substring(str.length - 4, str.length);
    return str1 + "..." + str2;
}


//如果数字>=1e21且最大精度为20，它将使用科学计数法。除此之外，您可以自己滚动，但会很混乱。
export function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

export function toolNumber(num_str) {
    num_str = num_str.toString();
    if (num_str.indexOf("+") != -1) {
        num_str = num_str.replace("+", "");
    }
    if (num_str.indexOf("E") != -1 || num_str.indexOf("e") != -1) {
        var resValue = "",
            power = "",
            result = null,
            dotIndex = 0,
            resArr = [],
            sym = "";
        var numStr = num_str.toString();
        if (numStr[0] == "-") {
            //如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
            numStr = numStr.substr(1);
            sym = "-";
        }
        if (numStr.indexOf("E") != -1 || numStr.indexOf("e") != -1) {
            var regExp = new RegExp(
                "^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$",
                "ig"
            );
            result = regExp.exec(numStr);
            if (result != null) {
                resValue = result[2];
                power = result[5];
                result = null;
            }
            if (!resValue && !power) {
                return false;
            }
            dotIndex = resValue.indexOf(".") == -1 ? 0 : resValue.indexOf(".");
            resValue = resValue.replace(".", "");
            resArr = resValue.split("");
            if (Number(power) >= 0) {
                var subres = resValue.substr(dotIndex);
                power = Number(power);
                //幂数大于小数点后面的数字位数时，后面加0
                for (var i = 0; i <= power - subres.length; i++) {
                    resArr.push("0");
                }
                if (power - subres.length < 0) {
                    resArr.splice(dotIndex + power, 0, ".");
                }
            } else {
                power = power.replace("-", "");
                power = Number(power);
                //幂数大于等于 小数点的index位置, 前面加0
                for (var i = 0; i < power - dotIndex; i++) {
                    resArr.unshift("0");
                }
                var n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
                resArr.splice(n, 0, ".");
            }
        }
        resValue = resArr.join("");
        return sym + resValue;
    } else {
        return num_str;
    }
}