<template>
  <el-header class="d-flex ai-center jc-between t-white">
    <div class="d-flex ai-center left">
      <img
        class="logo mx-20"
        src="@/assets/login/logo.webp"
        style="border-radius: 10px"
        height="45px"
      />
      <!-- 市场 -->
      <div :class="['hidden-xs-only item', { item_active: name === 'Marketplace' }]" @click="goGun">
        <img src="@/assets/menu/marketplace.png" alt="" />
        <span>{{ $t("menu.market") }}</span>
      </div>
      <el-tooltip class="item" effect="dark" :content="$t('menu.app') + ' APP'" placement="bottom">
        <i class="el-icon-download cursor" style="font-size: 20px;" @click="navTo"></i>
      </el-tooltip>
      <template>
        <!-- <div v-if="isLogin" :class="['hidden-xs-only item', { item_active: name === 'Dragon' }]"
        @click="dragonClick">
        <img src="@/assets/menu/touxiang.png" alt="" />
        <span>{{ $t("menu.game") }}</span>
      </div> -->

        <!-- 收集-->
        <!-- <a :href="collect" target="_blank" class="hidden-xs-only item">
          <img src="@/assets/menu/collect.png" alt=""/>
          <span class="t-white">{{ $t("menu.collect") }}</span>
        </a> -->

        <!-- 孵化 -->
        <!-- <a :href="hatching" target="_blank" class="hidden-xs-only item">
          <img src="@/assets/menu/hatching.png" alt=""/>
          <span class="t-white">{{ $t("menu.hatching") }}</span>
        </a> -->

        <!-- <a v-if="_i18n.locale == 'tw'" :href="whitePaperzh" target="_blank" class="hidden-xs-only item">
          <img src="@/assets/menu/whitepaper.png" alt=""/>
          <span class="t-white">{{ $t("menu.whitepaper") }}</span>
        </a>
        <a v-else-if="_i18n.locale == 'en'" :href="whitePaperen" target="_blank" class="hidden-xs-only item">
          <img src="@/assets/menu/whitepaper.png" alt=""/>
          <span class="t-white">{{ $t("menu.whitepaper") }}</span>
        </a>
        <a v-else :href="whitePaperin" target="_blank" class="hidden-xs-only item">
          <img src="@/assets/menu/whitepaper.png" alt=""/>
          <span class="t-white">{{ $t("menu.whitepaper") }}</span>
        </a> -->
        <!-- 游戏 -->
        <!-- <div :class="['hidden-xs-only item', { item_active: name === 'Game' }]" @click="gameVisible = true">
          <img src="@/assets/menu/touxiang.png" alt=""/>
          <span>{{ $t("menu.game") }}</span>
        </div> -->
        <!-- 小龙游戏 -->
        <!-- <div v-if="isLogin" :class="['hidden-xs-only item', { item_active: name === 'Dragongame' }]" @click="goGame">
          <img src="@/assets/menu/dragon.png" alt="" />
          <span>{{ $t("menu.dragongame") }}</span>
        </div> -->
      </template>
    </div>
    <div class="right d-flex ai-center">
      <template v-if="isLogin">
        <!-- 选择角色 -->
        <div class="roleox">
          <!-- <el-select v-model="serverid" size="medium" @change="onCommand" :placeholder="$t('menu.selectRole')">
            <div class="d-flex flex-column ai-center jc-center">
              <el-option v-for="item in userServeList2" :key="item.server_id"
                         :label="item.title + (item.nickname ? '-' + item.nickname : '')" :value="item.server_id"
                         class="d-flex ai-center jc-between">
                <span>{{
                    item.title + (item.nickname ? "-" + item.nickname : "")
                  }}</span>
                <span class="fs-sm" v-if="serverid == item.server_id">{{
                    $t("menu.nowServe")
                  }}</span>
              </el-option>
              //<el-button
                @click="create()"
                class="w-100"
                :disabled="roledisabled"
                >{{ $t("menu.createRole") }}</el-button
              >
            </div>
            <template #empty>
              <el-button @click="create()" class="w-100" :disabled="roledisabled">
                {{ $t("menu.createRole") }}
              </el-button>
            </template>
          </el-select> -->
        </div>
        <!-- 用户地址 -->
        <div class="hidden-xs-only item2 d-flex ai-center">
          <img alt="" src="@/assets/menu/address.png" />
          <span>{{ addressFilter(info.address || infos.address) }}</span>
        </div>
        <!-- 我的账户 -->
        <div
          :class="['hidden-xs-only account item', { item_active: isAccountActive }]"
          @click="goLink('/Account')"
        >
          <img alt="" src="@/assets/menu/account.png" />
          <span>{{ $t("menu.my") }}</span>
        </div>
      </template>
      <div
        :class="['item hidden-xs-only', { item_active: name === 'Login' }]"
        @click="goLink('/login')"
        v-if="!isLogin"
      >
        <img src="@/assets/menu/login.png" alt="" />
        <span>{{ $t("menu.login") }}</span>
      </div>
      <div class="item hidden-sm-and-up" @click="menuShow = true">
        <img class="menu" alt="menu" src="../assets/menu/menu.png" />
      </div>
    </div>
    <el-drawer
      :visible.sync="menuShow"
      :with-header="false"
      :size="280"
      direction="rtl"
      custom-class="drawer-header t-white"
      :modal-append-to-body="false"
    >
      <el-collapse class="collapse-header px-10 py-5" accordion>
        <!-- 市场 -->
        <el-collapse-item name="0">
          <template #title>
            <div class="d-flex ai-center">
              <img
                class="m-r-5"
                alt="marketplace"
                src="@/assets/menu/marketplace.png"
                style="height: 34px"
              />
              {{ $t("menu.market") }}
            </div>
          </template>
          <div style="padding-left: 40px">
            <el-collapse class="collapse-header" accordion>
              <p class="border_b py-10" @click="goLink('/marketplace')">
                {{ $t("menu.market") }}
              </p>
              <template v-if="$storage.get('tabindex') == 1">
                <!-- 技能属性 -->
                <el-collapse-item name="1">
                  <template #title>
                    <p>{{ $t("market.skillTitle") }}</p>
                  </template>
                  <div style="padding-left: 20px">
                    <p
                      style="padding: 5px 0"
                      :class="[{ item_active: $route.params.skillType == i }]"
                      @click="changeMarket('', '', '', '', '', item.value)"
                      v-for="(item, i) in skillList"
                      :key="i"
                    >
                      {{ item.label }}
                    </p>
                  </div>
                </el-collapse-item>
                <!-- 级别 -->
                <el-collapse-item name="2">
                  <template #title>
                    <p>{{ $t("market.grade") }}</p>
                  </template>
                  <div style="padding-left: 20px">
                    <p
                      style="padding: 5px 0"
                      :class="[{ item_active: $route.params.grade == i }]"
                      @click="changeMarket('', '', '', '', item.value)"
                      v-for="(item, i) in gradeList"
                      :key="i"
                    >
                      {{ item.label }}
                    </p>
                  </div>
                </el-collapse-item>
                <!-- 排序 -->
                <el-collapse-item name="3">
                  <!-- 排序 -->
                  <template #title>
                    <p>{{ $t("market.sorttitle") }}</p>
                  </template>
                  <div style="padding-left: 20px">
                    <p
                      style="padding: 5px 0"
                      :class="[{ item_active: $route.params.order == i }]"
                      @click="changeMarket('', '', '', item.value, '')"
                      v-for="(item, i) in sortTypeList"
                      :key="i"
                    >
                      {{ item.label }}
                    </p>
                  </div>
                </el-collapse-item>
              </template>
              <template v-else>
                <!-- 种族 -->
                <el-collapse-item name="1">
                  <template #title>
                    <p>{{ $t("market.camp") }}</p>
                  </template>
                  <div style="padding-left: 20px">
                    <p
                      style="padding: 5px 0"
                      @click="changeMarket(item.value, '', '', '', '')"
                      v-for="(item, i) in campList"
                      :key="i"
                    >
                      {{ item.label }}
                    </p>
                  </div>
                </el-collapse-item>
                <!-- 职业 -->
                <el-collapse-item name="2">
                  <template #title>
                    <p>{{ $t("market.profession") }}</p>
                  </template>
                  <div style="padding-left: 20px">
                    <p
                      style="padding: 5px 0"
                      :class="[{ item_active: $route.params.partId == i }]"
                      @click="changeMarket('', item.value, '', '', '')"
                      v-for="(item, i) in professionList"
                      :key="i"
                    >
                      {{ item.label }}
                    </p>
                  </div>
                </el-collapse-item>
                <!-- 排序 -->
                <el-collapse-item name="3">
                  <!-- 排序 -->
                  <template #title>
                    <p>{{ $t("market.sorttitle") }}</p>
                  </template>
                  <div style="padding-left: 20px">
                    <p
                      style="padding: 5px 0"
                      :class="[{ item_active: $route.params.order == i }]"
                      @click="changeMarket('', '', item.value, '', '')"
                      v-for="(item, i) in sortTypeList"
                      :key="i"
                    >
                      {{ item.label }}
                    </p>
                  </div>
                </el-collapse-item>
              </template>
            </el-collapse>
          </div>
        </el-collapse-item>

        <!-- 收集-->
        <!-- <a :href="collect" target="_blank" class="whitepaper">
          <img src="@/assets/menu/collect.png" alt=""/>
          <span class="t-white m-l-5">{{ $t("menu.collect") }}</span>
        </a> -->

        <!-- 孵化 -->
        <!-- <a :href="hatching" target="_blank" class="whitepaper">
          <img src="@/assets/menu/hatching.png" alt=""/>
          <span class="t-white m-l-5">{{ $t("menu.hatching") }}</span>
        </a> -->

        <!-- <a :href="_i18n.locale == 'tw'?whitePaperzh:_i18n.locale == 'en'?whitePaperen:whitePaperin" target="_blank"
           class="whitepaper">
          <img src="@/assets/menu/whitepaper.png" alt=""/>
          <span class="t-white m-l-5">{{ $t("menu.whitepaper") }}</span>
        </a> -->
        <template v-if="isLogin">
          <!-- 小龙游戏 -->
          <!-- <div v-if="isLogin" :class="['item fs-md t-white', { item_active: name === 'Dragongame' }]" @click="goGame">
            <img alt="game" src="@/assets/menu/dragon.png" style="height: 34px" />
            {{ $t("menu.dragongame") }}
          </div> -->

          <!-- 游戏 -->
          <!-- <div :class="['item fs-md t-white', { item_active: name === 'Game' }]" @click="gameVisible = true">
            <img alt="game" src="@/assets/menu/touxiang.png" style="height: 34px"/>
            {{ $t("menu.game") }}
          </div> -->

          <!-- 我的账户 -->
          <template v-if="isLogin">
            <el-collapse-item name="1">
              <template #title>
                <p class="d-flex ai-center">
                  <img
                    alt="app"
                    src="../assets/menu/account.png"
                    style="height: 34px"
                    class="m-r-5"
                  />
                  {{ $t("menu.my") }}
                </p>
              </template>
              <div style="padding-left: 40px">
                <p
                  :class="[{ item_active: name === 'AccountAccount' }, 'py-5']"
                  @click="goLink('/account')"
                >
                  {{ $t("menu.account") }}
                </p>
                <p
                  :class="[{ item_active: name === 'AccountActivity' }, 'py-5']"
                  @click="goLink('/activity')"
                >
                  {{ $t("menu.activity") }}
                </p>
                <!-- <p :class="[{ item_active: name === 'AccountLegion' }, 'py-5']" @click="goLink('/legion')">
                  {{ $t("menu.legion") }}
                </p> -->
                <p
                  :class="[{ item_active: name === 'AccountShare' }, 'py-5']"
                  @click="goLink('/shares')"
                >
                  {{ $t("menu.share") }}
                </p>
                <p
                  :class="[{ item_active: name === 'AccountSetting' }, 'py-5']"
                  @click="goLink('/setting')"
                >
                  {{ $t("menu.setting") }}
                </p>
              </div>
            </el-collapse-item>
          </template>

          <!-- 退出 -->
          <div class="item fs-md t-white" @click="logoutFun">
            <img alt="logout" src="../assets/account/logout.png" style="height: 25px" />
            {{ $t("menu.logout") }}
          </div>
        </template>
        <div
          v-else
          :class="['fs-lg item t-white', { item_active: name === 'Login' }]"
          @click="goLink('/login')"
        >
          <img alt="login" src="../assets/menu/login.png" style="height: 25px" />
          {{ $t("menu.login") }}
        </div>
      </el-collapse>
    </el-drawer>
    <el-dialog
      :title="$t('messageBox.createRoleTitle')"
      :visible.sync="createRoleVisible"
      :modal-append-to-body="false"
    >
      <el-form>
        <el-form-item :label="$t('messageBox.selectServe')">
          <el-select
            v-model="createRoleId"
            :placeholder="$t('messageBox.selectTip')"
            :no-data-text="$t('messageBox.noServers')"
          >
            <el-option
              v-for="item in websiteList2"
              :key="item.server_id"
              :label="item.name || item.nickname"
              :value="item.server_id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="createLoading" class="sure_btn" @click="onCreateRole()">
          {{ $t("messageBox.confirm") }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="gameVisible"
      :modal-append-to-body="false"
      :title="$t('messageBox.gametitle')"
    >
      <div class="d-flex ai-center flex-column jc-center">
        <vue-qr v-if="qrStr" :text="qrStr" :size="160" :margin="8"></vue-qr>
        <a :href="qrStr" target="_blank" class="download">{{ $t("menu.app") }}</a>
      </div>
    </el-dialog>
  </el-header>
</template>

<script>
import { mapState } from "vuex";
import { logout } from "@/api/common/index.api";

export default {
  components: {},
  inject: ["reload"],
  data() {
    return {
      name: this.$route.name,
      menuShow: false,
      createLoading: false,
      createRoleId: "", //创建角色时选择的区服id
      createRoleVisible: false,
      isAccountActive: false,
      roledisabled: false,
      gameVisible: false,
      userServeList2: [], //用户所有区服列表
      websiteList2: [], //创建角色时的区服列表(所有区服列表去掉已激活列表)
      info: {},
      grade: "",
      whitePaperzh: "https://metadragon.gitbook.io/zh/",
      whitePaperen: "https://metadragon.gitbook.io/en/",
      whitePaperin: "https://metadragon.gitbook.io/id/",
      collect: "https://collect.metadragon.ai/",
      hatching: "https://hatch.metadragon.ai/",
      gradeList: [
        { value: "1", label: "1~5" },
        { value: "2", label: "6~10" },
        { value: "3", label: "11~15" },
        { value: "4", label: "16~20" },
        { value: "5", label: "21~24" },
      ],
    };
  },
  watch: {
    $route: "getPath",
    userServeList(n, o) {
      console.log("userServeList=>", n);
      if (n) {
        this.userServeList2 = JSON.parse(n);
      }
    },
    serverid(n, o) {
      console.log("serverid=>", n);
      if (n) {
        this.$store.dispatch("Get_infos");
      }
    },
    // websiteList(n, o) {
    //   console.log("websiteList=>", n);
    //   if (n) {
    //     this.websiteList2 = JSON.parse(n);
    //     // if (this.websiteList2.length <= 0) {
    //     //   this.roledisabled = true;
    //     // } else {
    //     //   this.roledisabled = false;
    //     // }
    //   }
    // },
    infos(n, o) {
      if (n) {
        this.info = n;
      }
    },
  },
  computed: {
    ...mapState({
      sets: (state) => state.sets,
      infos: (state) => state.infos,
      isLogin: (state) => state.isLogin,
      serverid: (state) => state.serverid, //当前用户的区服id
      userServeList: (state) => state.userServeList, //用户所有区服列表
      // websiteList: (state) => state.websiteList, //创建角色时的区服列表
    }),
    qrStr() {
      return "https://app.metadragon.ai/?lang=" + this._i18n.locale;
      // return "http://192.168.1.18:8083/#/?lang=" + this._i18n.locale
    },
    sortTypeList() {
      return [
        { value: "new,desc", label: this.$t("market.sort.latest") },
        { value: "price,asc", label: this.$t("market.sort.lowP") },
        { value: "price,desc", label: this.$t("market.sort.hightP") },
      ];
    },
    skillList() {
      return [
        { value: 0, label: this.$t("market.skill.element1") },
        { value: 1, label: this.$t("market.skill.element2") },
        { value: 2, label: this.$t("market.skill.element3") },
        { value: 3, label: this.$t("market.skill.element4") },
        { value: 4, label: this.$t("market.skill.element5") },
        { value: 5, label: this.$t("market.skill.element6") },
      ];
    },
    campList() {
      return [
        { value: 0, label: this.$t("market.campList.element1") },
        { value: 1, label: this.$t("market.campList.element2") },
        { value: 2, label: this.$t("market.campList.element3") },
        { value: 3, label: this.$t("market.campList.element4") },
        { value: 4, label: this.$t("market.campList.element5") },
        { value: 5, label: this.$t("market.campList.element6") },
      ];
    },
    professionList() {
      return [
        { value: 0, label: this.$t("market.professionList.element1") },
        { value: 2, label: this.$t("market.professionList.element3") },
        { value: 3, label: this.$t("market.professionList.element2") },
        { value: 4, label: this.$t("market.professionList.element4") },
        { value: 5, label: this.$t("market.professionList.element5") },
      ];
    },
    starrateList() {
      return [
        { value: 0, label: this.$t("market.starrateList.star1") },
        { value: 1, label: this.$t("market.starrateList.star2") },
        { value: 2, label: this.$t("market.starrateList.star3") },
        { value: 3, label: this.$t("market.starrateList.star4") },
        { value: 4, label: this.$t("market.starrateList.star5") },
        { value: 5, label: this.$t("market.starrateList.star6") },
      ];
    },
  },
  mounted() {
    this.$store.dispatch("Get_infos");
    this.getlist();
  },
  methods: {
    navTo() {
      window.open(this.sets.down_url);
    },
    getlist() {
      this.userServeList2 = JSON.parse(this.userServeList);
      // console.log(this.userServeList2);
      // this.websiteList2 = JSON.parse(this.websiteList);
      this.info = this.infos;
      // if (this.websiteList2.length <= 0) {
      //   this.roledisabled = true;
      // } else {
      //   this.roledisabled = false;
      // }
    },
    onCommand(e) {
      this.$store.commit("Set_serverid", e);
      this.$store.dispatch("Get_infos");
      if (this.$storage.has("token")) this.$store.dispatch("Get_websiteList");
    },
    getPath() {
      this.name = this.$route.name;
      // console.log(this.name);
      if (this.name === "accountAccount") {
        this.isAccountActive = true;
      } else if (this.name === "setting") {
        this.isAccountActive = true;
      } else if (this.name === "activity") {
        this.isAccountActive = true;
      } else if (this.name === "shares") {
        this.isAccountActive = true;
      } else {
        this.isAccountActive = false;
      }
    },
    goGun() {
      this.$router.push("/marketplace");
    },
    goGame() {
      return this.$message(this.$t("messageBox.noopen"));
      this.$router.push("/dragong");
      if (this.menuShow) this.menuShow = false;
      // this.$router.push('/dragon/index.html?token=' + this.$storage.get('token') + '&server_id=2&language=tw');
    },
    addressFilter(str) {
      if (!str) return "--";
      const str1 = str.substring(0, 5);
      const str2 = str.substring(str.length - 4, str.length);
      return str1 + "..." + str2;
    },
    goLink(path) {
      if (this.$route.path == path) return;
      if (this.$route.matched[0].path == path) return;
      this.$router.push(path);
      this.menuShow = false;
    },
    changeMarket(val1, val2, val3, val4, val5, val6) {
      console.log("val4", val4);
      console.log("val5", val5);
      this.$router.push({
        path: "/marketplace",
        query: { val1, val2, val3, val4, val5, val6 },
      });
      if (this.menuShow) this.menuShow = false;
    },
    //退出登录
    logoutFun() {
      this.$msgbox.alert(this.$t("messageBox.logoutTip"), this.$t("messageBox.tip"), {
        confirmButtonText: this.$t("messageBox.confirm"),
        center: true,
        callback: (action) => {
          if (action === "confirm") {
            logout({}, { Serverid: this.serverid });
            setTimeout(() => {
              this.$storage.remove("token");
              this.$store.commit("Set_serverid", "");
              this.$store.commit("Set_isLogin", false);
              this.$router.push("/marketplace");
              this.menuShow = false;
              setTimeout(() => {
                this.reload();
              }, 200);
            }, 500);
          }
        },
      });
    },
    //创建角色
    create() {
      if (!this.serverid) {
        this.$msgbox.confirm(this.$t("messageBox.createRole"), this.$t("messageBox.tip2"), {
          confirmButtonText: this.$t("messageBox.confirm"),
          cancelButtonText: this.$t("messageBox.cancel"),
          cancelButtonClass: "cancel_btn",
          center: true,
          callback: (action) => {
            if (action === "confirm") {
              this.createRoleVisible = true;
            }
          },
        });
      } else {
        this.createRoleVisible = true;
      }
    },
    //创建角色确认
    onCreateRole() {
      this.createLoading = true;
      this.$axios
        .post(
          this.$api.create_player + "/" + this.createRoleId,
          {},
          { Serverid: this.createRoleId }
        )
        .then((res) => {
          this.createLoading = false;
          if (res.code == 200) {
            this.createRoleVisible = false;
            this.getuserServeList();
          }
        })
        .catch((err) => {
          this.createLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scope>
.border_b {
  border-bottom: 1px solid #fff;
}

.el-header {
  width: 100%;
  background-color: #0f131b !important;

  .download {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    background: #317f6e !important;
    border-radius: 4px !important;
    color: #fff;
    margin-top: 20px;
  }

  .item_active {
    background: rgba(33, 34, 47, 0.5);
  }

  .item {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0 10px;
    height: 100%;
    cursor: pointer;

    img {
      height: 34px;
      margin-right: 10px;
    }
  }

  .whitepaper {
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 13px;

    img {
      height: 34px;
    }
  }

  .item2 {
    padding: 0 20px;
    height: 60px;

    img {
      height: 34px;
      margin-right: 10px;
    }
  }

  .item:hover {
    background: rgba(33, 34, 47, 0.5);
  }

  .dropdown {
    height: 38px;
    line-height: 38px;
    color: #fff;
    border: 1px solid #999999;
    border-radius: 4px;
    padding: 0 10px;
  }

  .roleox {
    width: 200px;
    margin-right: 10px;
  }
}

.el-input__inner {
  color: #fff !important;
  background-color: transparent !important;
}

.el-select-dropdown__item {
  width: 100%;
  text-align: center;
}

.el-button {
  height: 30px;
  line-height: 30px !important;
  padding: 0 20px !important;
  color: #fff !important;
  border: none !important;
  margin-top: 15px !important;
  font-size: 13px !important;
}

.drawer-header {
  line-height: 48px;

  .item {
    // padding: 0 10px !important;
  }

  .item_active {
    background: rgba(33, 34, 47, 0.5);
    color: #fff;
  }
}

.cancel_btn {
  background: transparent !important;
  border: 1px solid #37b497 !important;
  color: #fff !important;
}

.el-select {
  width: 100%;
}

.sure_btn {
  height: 36px;
  width: 100%;
}

.el-drawer,
.el-collapse-item__header,
.el-collapse-item__wrap {
  color: #fff !important;
  background: rgb(33, 34, 47) !important;
}

.el-collapse-item__content {
  color: #fff !important;
}

.el-radio {
  width: 50%;
  margin-right: 0;
  margin-bottom: 10px;
  margin-right: 0 !important;
}

.el-radio__inner {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .el-header {
    padding: 0 !important;

    .item {
      padding: 0;
      margin: 0;

      img {
        // margin-left: 10px;
      }
    }

    .logo {
      margin: 0 10px;
    }
  }
}
</style>
