export default {
  getmsg: '/api/auth/msg', //获取登录签名信息
  getMarketList: '/api/marketplace/list', // 市场列表
  getuserPlayerss: '/api/user/players', // 获取用户已激活角色的区服列表
  getwebsiteList: '/api/website/servers', // 获取所有区服列表
  getToken: '/api/user/refresh', // 刷新游戏token
  sendCode: '/api/user/send_code', // 发送邮件（邮箱验证码）
  bindEmail: '/api/user/bind_email', // 绑定邮箱
  loginEmail: '/api/auth/login/email', // 邮箱登录
  create_player: '/api/user/create_player', // 创建游戏角色
  charge: '/api/user/charge', // 充币 和 充金币结果回调
  withdraw: '/api/user/withdraw', // 提币
  resetName: '/api/user/nickname', // 修改昵称
  getActivityList: '/api/user/activities', // 获取用户活动记录
  getinviteList: '/api/user/invite_list', // 我的邀请记录
  buy: '/api/card/buy', // 装备买 预备
  buyback: '/api/card/buyback', // 装备买 回调
  historyk: '/api/assets/history', // 查询装备交易历史
  charge2: '/api/user/charge2', // 充币 和 充金币结果回调
  withdraw2: '/api/user/withdraw2', // 提币
  assetshistory: '/api/user/summon', // 领取召唤券
  addMDragon: '/api/add/mdragon',
  receiveDragon: '/api/artifact/activate', // 领取元龍
  forgetreset: '/api/user/forget/reset', // 重置密码
  chargebgg: '/api/user/bgg/charge', // BGG充值接口
  withdrawbgg: '/api/user/bgg/withdraw', // BGG提现接口

  getUserTeam: '/api/user/team',
  receiveMds: '/api/receive/mds',
  integralRanks: '/api/integral/rank',
  integralActivity: '/api/integral/active',

  bggactive: '/api/user/bgg/active', // BGG激活vip
  brpactive: '/api/user/brp/active', // MDV激活vip
  getArtifactList: '/api/artifact/list', // 元龍市场
};