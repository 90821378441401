import http from "../../api/request"

//我的用户信息
export const getInfos = (data, headers) => http.get("/api/user/info", data, headers);

// 修改昵称
export const resetName = (data, headers) => http.post("/api/user/nickname", data, headers);

// 重置登录密码
export const resetLoginPass = (data, headers) => http.post("/api/user/password", data, headers);

// 重置资金密码
export const resetFundPass = (data, headers) => http.post("/api/user/paypwd", data, headers);

//退出登录
export const logout = (params,headers) => http.post("/api/user/logout", params, headers);

// 用户展示元龍列表
export const getDragons = (params, headers) => http.get("/api/user/dragons", params, headers);

// 用户展示元龍详情
export const getDragonsDetail = (id, params, headers) => http.get("/api/user/dragons/" + id, params, headers);

// 获取用户英雄列表
export const getcards = (params, headers) => http.get("/api/user/card/list", params, headers);

// 用户展示英雄详情
export const getcardsDetail = (id, params, headers) => http.get("/api/card/" + id, params, headers);

// 获取用户活动记录
export const getActivitys = (params, headers) => http.get("/api/user/activities", params, headers);

//获取区服列表
// export const getwebsiteList = (params) => http.get("/api/website/servers", params);

// 获取配置信息
export const getSetting = (params, headers) => http.get("/api/website/setting", params, headers);

// 激活元龍
export const activeDragon = (id, params, headers) => http.post("/api/user/active/" + id, params, headers);

// bgg激活元龍
export const activebggDragon = (id, params, headers) => http.post("/api/user/bgg/active/" + id, params, headers);

// 出售卡片预备
export const sellCards = (id, params, headers) => http.post("/api/card/sell/" + id, params, headers);

// 出售卡片回调
export const sellCardsCallback = (id, params, headers) => http.post("/api/card/sellback/" + id, params, headers);

// 取消出售卡片预备
export const cancelCards = (id, params, headers) => http.post("/api/card/cancel/" + id, params, headers);

// 取消出售卡片回调
export const cancelCardsCallback = (id, params, headers) => http.post("/api/card/cancelback/" + id, params, headers);

// NFT存入
export const depositCards = (params, headers) => http.post("/api/card/deposit", params, headers);
// NFT领取
export const claimCards = (params, headers) => http.post("/api/card/claim", params, headers);

//获取所有区服列表
export const getwebsiteList = (params, headers) => http.get("/api/website/servers", params, headers);

//获取用户已激活角色的区服列表
export const getuserPlayerss = (params, headers) => http.get("/api/user/players", params, headers);

//错误信息收集接口
export const messageCollect = (params, headers) => http.post("/api/message/collect", params, headers);
//拍卖回调
export const business = (params, headers) => http.post("/api/user/card/business", params, headers);
//获取添加对应级别元龙金额列表
export const getLevelList = (params, headers) => http.get("/api/level/fee", params, headers);
//生成卡牌
export const createCard = (params, headers) => http.post("/api/mint/card", params, headers);
//生成卡牌回调
export const createCard_callback = (params, headers) => http.post("/api/mint/callback", params, headers);
