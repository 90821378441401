const routers = [
    //配置默认页面或者404页面
    {
        path: "/",
        name: "",
        redirect: "/"
    },
    {
        path: "*",
        name: "",
        redirect: "/"
    },
]

export default routers;
