<template>
  <div id="app">
    <div :class="{ root: $route.name != 'Login' }">
      <top />
      <router-view v-if="isRouterAlive"></router-view>
    </div>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import abiArray20 from "./common/abi/abiArray20.json";
export default {
  components: { Top },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
      isLogin: (state) => state.isLogin,
      sets: (state) => state.sets,
    }),
  },
  watch: {
    clientHeight() {
      //如果clientHeight 发生改变，这个函数就会运行
      this.changeFixed(this.clientHeight);
    },
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {
    // this.keepLogin();
  },
  mounted() {
    // 获取可视化窗口高度
    this.clientHeight = `${document.documentElement.clientHeight}`; //获取浏览器可视区域高度
    let that = this;
    window.onresize = function () {
      this.clientHeight = `${document.documentElement.clientHeight}`;
      if (that.$refs.page) {
        that.$refs.page.style.minHeight = clientHeight + "px";
      }
    };
    this.getSet();
  },
  methods: {
    ...mapMutations(["Set_isLogin"]),
    keepLogin() {
      console.log("keepLogin", this.$storage.has("token"));
      if (this.$storage.has("token")) {
        // this.Set_isLogin(true);
        this.$store.dispatch("Get_websiteList");
      } else {
        this.$router.replace("/login");
        // this.Set_isLogin(false);
      }
    },
    //  // 获取用户信息
    // getInfos(){
    //   this.$axios.post("/api/user/info",{},{Authorization:1}).then(res=>{
    //     console.log(res)
    //   })
    // },
    changeFixed(clientHeight) {
      if (this.$refs.page) {
        this.$refs.page.style.minHeight = clientHeight + "px";
      }
    },
    async getSet() {
      await this.$store.dispatch("Get_setting");
      await this.$store.dispatch("Get_infos");
      var addres20 = this.sets.coin_contract_address;
      window.myContract20 = new web3.eth.Contract(abiArray20, addres20);
      if (!this.$store.state.decimals1) {
        myContract20.methods
          .decimals()
          .call({
            from: this.$store.state.infos.address,
          })
          .then((res) => {
            this.$store.commit("Set_decimals1", res);
          });
      }
      if (!this.$store.state.coinName1) {
        myContract20.methods
          .symbol()
          .call({
            from: this.$store.state.infos.address,
          })
          .then((res) => {
            console.log(res);
            this.$store.commit("Set_coinName1", res);
          });
      }
    },
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  position: relative;
  font-family: "FZY1JW";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  .root {
    min-height: 100vh;
    background-color: rgba(33, 34, 47, 0.5);
  }
}
.el-dialog {
  background: rgba(41, 43, 57, 0.9) !important;
  border: 1px solid #fffce5 !important;
  border-radius: 10px !important;
  max-width: 380px !important;
  min-width: 300px !important;
}
.el-dialog .el-input__inner {
  background: #0f1016 !important;
  border-radius: 4px !important;
  border: none !important;
  color: #fff !important;
}
.el-button {
  background: #317f6e !important;
  border-radius: 4px !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #317f6e !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fff !important;
}

.el-checkbox {
  color: #fff !important;
}
.el-dialog__title {
  color: #fff !important;
}
.el-timeline-item__timestamp {
  color: #fff !important;
}
.el-timeline-item__tail {
  border-left: 2px solid #37B497 !important;
}
.el-timeline-item__node {
  background: #37B497 !important;
}
</style>
