const routers = [
  {
    path: "/",
    name: 'Home',
    redirect: '/login',
    component: () => import("@/views/home.vue")
  },
  {
    path: "/login",
    name: 'Login',
    component: () => import("@/views/Login.vue")
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('@/views/Forget.vue')
  },
  {
    path: "/Account",
    name: 'Account',
    redirect: '/accountAccount',
    component: () => import("@/views/Account.vue"),
    children: [
      {
        path: "/accountAccount",
        name: 'accountAccount',
        component: () => import("@/views/accountAccount.vue")
      },
      {
        path: "/activity",
        name: 'activity',
        component: () => import("@/views/activity.vue")
      },
      {
        path: "/legion",
        name: 'legion',
        component: () => import("@/views/legion.vue")
      },
      {
        path: "/legion/rules",
        name: 'legionRules',
        component: () => import("@/views/legionRules.vue")
      },
      {
        path: "/legion/ranks",
        name: 'legionRanks',
        component: () => import("@/views/legionRanks.vue")
      },
      {
        path: "/legion/history",
        name: 'legionHistory',
        component: () => import("@/views/legionHistory.vue")
      },
      {
        path: "/shares",
        name: 'shares',
        component: () => import("@/views/shares.vue")
      },
      {
        path: "/setting",
        name: 'setting',
        component: () => import("@/views/setting.vue")
      },
      {
        path: '/dragonDetail',
        name: 'DragonDetail',
        component: () => import('@/views/dragonDetail.vue')
      },
      {
        path: '/cardDetail',
        name: 'CardDetail',
        component: () => import('@/views/cardDetail.vue')
      },
      // {
      //   path: '/activityDetail',
      //   name: 'ActivityDetail',
      //   component: () => import('@/views/activityDetail.vue')
      // },
    ]
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: () => import('@/views/Marketplace.vue')
  },
  {
    path: '/dragong',
    name: 'Dragongame',
    component: () => import('@/views/dragongame.vue')
  },
]

export default routers;