import axios from "axios"; // 引用axios
import storage from 'storejs'
import { Message } from 'element-ui'
// import router from '../router/index.js'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const router = new VueRouter()
const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  // baseURL:"http://18.141.216.50:8060",
  // baseURL: "http://108.136.244.136:8230", //测试
  // baseURL: "https://api.metadragon.ai"
  // baseURL: 'http://192.168.1.199:8087', //张凯本地
  // timeout: 60000,
});


const lang = {
  cn: 'ZH-CN',
  tw: 'ZH-TW',
  en: 'EN-US',
  in: 'IN-IN'
}
instance.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + storage.get('token');
  config.headers.Language = lang[storage.get('locale') || 'tw'];
  // config.headers.Serverid = storage.get('?Serverid')?storage.get('Serverid')[0]:1;
  // config.headers.Roleid = storage.get('?Serverid')?storage.get('Serverid')[1]:1;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
instance.interceptors.response.use(response => {
  try {
    if (response.data.code == 200) return response.data;
    else {
      if (response.data.code == 401/*  && storage.get('?token') */) {
        storage.remove('token');
        setTimeout(() => {
          router.replace('/')
        }, 500);
      }
      Message(response.data.msg || response.statusText || 'Error');
      return Promise.reject(response);
    }
  } catch (e) {
    Message(response.data.msg || response.statusText || 'Error');
    return Promise.reject(response);
  }
}, error => {
  // console.log(error);
  if (!error) {
    Message('Server Error');
    return Promise.reject({ msg: 'Server Error' })
  }
  try {
    if (error.response.status == 401/*  && storage.get('?token') */) {
      storage.remove('token');
      setTimeout(() => {
        router.replace('/')
      }, 500);
    }
    Message(error.response.data.msg || error.response.statusText || 'Error');
    return Promise.reject(error.response);
  } catch (e) {
    Message(error.response.data.msg || error.response.statusText || 'Error');
    return Promise.reject(error.response);
  }
});

export default {
  //get请求
  get(url, params = {}, headers = {}) {
    return new Promise((resolve, reject) => {
      instance
        .get(url, {
          params: params,
          headers: headers
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //post请求
  post(url, data = {}, headers = {}) {
    return new Promise((resolve, reject) => {
      instance.post(url, data, { headers }).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}